// 下载存储安装包
.config-fix {
    .config-fix-box {
        display: inline-flex;

        .config-fix-text {
            font-size: 16px;
            font-family: HiraginoSansGB-W3, HiraginoSansGB;
            font-weight: normal;
            color: #324558;
            line-height: 28px;
            padding-left: 4px;
        }
    }
}

// 安装操作系统配置网络
.config-system-box {
    >span {
        display: inline-block;
        margin-top: 24px;
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #324558;
        line-height: 28px;
    }
    >p{
        margin-top: 8px;
    }
    .config-system-li {
        >div {
            margin-top: 8px;
            display: flex;

            >span {
                display: inline-block;
                font-family: HiraginoSansGB-W3, HiraginoSansGB;
                min-width: 120px;
                font-size: 14px;
                color: #324558;
                line-height: 28px;
            }
        }

    }
}

// 配置存储
.config-save {
    .config-save-box {
        p{
            font-size: 14px;
            font-family: HiraginoSansGB-W3, HiraginoSansGB;
            font-weight: normal;
            color: #324558;
            line-height: 28px;  
        }
        .config-table{
            width: 686px;
            background-color: #F5F6F7;
            border: 1px solid #DDE0E3;
            p{
                font-size: 12px;
            }
            .config-table-header{
                padding:10px 20px;
                width: 100%;
                background-color: #EFF0F1;
                border-bottom: 1px solid #DDE0E3;
            }
            .config-table-body{
                padding: 10px 40px;
                .config-table-body_div{
                    padding-left: 10px;
                }
            }
        }
        .config-tab-div{
            padding-left: 20px;
        }
    }
}
