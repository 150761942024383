// 下载客户安装包
.pack-client {
    .pack-client-ul {
        display: flex;
        flex-direction: column;

        li {
            height: 28px;
            margin-bottom: 24px;
            display: flex;

            >span {
                font-size: 16px;
                font-family: HiraginoSansGB-W3, HiraginoSansGB;
                font-weight: normal;
                color: #324558;
                line-height: 28px;
                padding-left: 4px;
            }
        }
    }
}

// 安装及配置客户端
.config-client {

    .config-client-box {
        padding-right: 25px;

        .client-title {
            margin-bottom: 24px;
            font-family: PingFangSC-Semibold, PingFang SC;
            color: #324558;
            font-weight: 600;
            font-size: 14px;

            &:last-child {
                margin-bottom: 20px;
            }

            span {
                display: block;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                line-height: 28px;
            }

            .client-title-h1 {
                font-size: 20px;
                line-height: 28px;
                margin-bottom: 8px;
            }

            .client-title-h2 {
                font-size: 16px;
                line-height: 28px;
                margin-bottom: 8px;
            }

            .client-title-h3 {
                font-size: 14px;
                line-height: 28px;
                margin-bottom: 8px;
            }

            .client-text-1 {
                margin-left: 0px;
            }

            .client-text-2,
            .client-text-3 {
                margin-left: 28px;
            }
            .src-img{
                width: 80%;
                padding-left: 10%;
            }
        }
    }
}
