// 公用
.upgrade-text {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #324558;
    line-height: 28px;
}

// License到期

.expare {}

// 升级至企业版

.enterprise-title {
    margin-bottom: 24px;
    font-family: PingFangSC-Semibold, PingFang SC;
    color: #324558;
    font-size: 14px;

    p {
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        margin-bottom: 8px;
    }

    span {
        font-family: PingFangSC-Regular, PingFang SC;
        display: block;
        font-weight: 400;
        line-height: 28px;
    }
}

// 扩容
.dilatation{}
