// 申请license
.license-asx {
    .free-button {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        width: 134px;
        height: 36px;
        background: #0FB187;
        border-radius: 4px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 20px;
    }
    >p{
        margin-top:20px;
    }
}

// 申请激活许可
.license-permit {
    .license-permit-box {
        .permit-img {
            width: 830px;
            height: 360px;
            display: flex;
            margin-left: 12px;
            justify-content: center;
            align-items: center;
            background: #F6FCFF;
            border-radius: 4px;

            img {
                width: 800px;
            }
        }

        >p {
            font-size: 14px;
            font-family: HiraginoSansGB-W3, HiraginoSansGB;
            font-weight: normal;
            color: #324558;
            line-height: 28px;
            margin-bottom: 8px;
        }
        .license-permit-box-license{
            padding-left: 28px;
            margin-bottom: 20px;
        }
    }
}

//
.license-body {

    .ant-modal-footer,
    .ant-modal-header {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .ant-modal-body {
        padding-top: 48px;
        padding-bottom: 48px;
    }

    .ant-modal-footer {
        .ant-btn {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            line-height: 20px;
            height: auto;
            padding: 0;

            color: #333333;
            border-radius: 4px;
            background: #ffffff;
            border: 1px solid #E6ECF4;

            &:hover,
            &:active,
            &:focus {
                background-color: #ffffff;
                border-color: #E6ECF4;
            }

            span {
                padding: 8px 28px;
            }
        }

        .ant-btn-primary {
            color: #FFFFFF;
            background: #0FB187;
            border-radius: 4px;
            border-color: #0FB187;

            &:hover,
            &:active,
            &:focus {
                background-color: #0FB187;
                border-color: #0FB187;
            }
        }
    }

    .license-upload {
        margin-bottom: 0 !important;

        .ant-form-item-control-input {
            min-height: 40px;
        }
        .ant-form-item-explain.ant-form-item-explain-error{
            padding-left: 120px;
        }
        .license-input-box {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            margin: 0 33px;

            .upload-name {
                min-width: 70px;
                display: block;
                margin-right: 16px;
            }
        }

        .ant-input {
            width: 400px;
            height: 40px;
            border: 1px solid #E6ECF4;

            &:hover,
            &:focus {
                border: 1px solid #E6ECF4;
                box-shadow: none;
            }
        }

        .upload-box {
            position: absolute;
            right: 6px;
        }

        .uploadBtn {
            display: inline-block;
            width: 80px;
            height: 28px;
            background: #E6ECF4;
            padding: 6px 16px;
            cursor: pointer;
            border-radius: 4px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #007AFF;
        }

    }
}
