@con_width: 1200px;

.header {
    width: 100%;
    height: 60px;
    background: #ffffff;
    display: flex;
    justify-content: center;

    .header-top {
        width: @con_width;
        height: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 24px;
        .header-top-left {
            span {
                font-family: HiraginoSansGB-W3, HiraginoSansGB;
                font-size: 16px;
                color: #324558;
                cursor: pointer;
                margin-left: 40px;
            }
        }
        .header-top-right {

            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            line-height: 20px;
            display: flex;

            span {
                color: #324558;
            }

            b {
                font-weight: 400;
                color: #007AFF;
                cursor: pointer;
            }
        }
    }

}
