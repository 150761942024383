.verify-box {
    background-color: #fff;
    height: 40px;
    text-align: center;

    img {
        height: 100%;
        width: 100%;
        cursor: pointer;
    }
}

.go-activate {
    padding: 0 16px;
    width: 400px;
    height: 40px;
    line-height: 40px;
    background-color: rgba(0, 122, 255, 0.08);
    border-radius: 4px;

    .activate-icon {
        color: #007aff;
        margin-right: 16px;
    }
}
