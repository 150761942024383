.lay-content {
    width: 1200px;
    margin: 0 auto;
    margin-top: 20px;
    background: #ffffff;
    box-shadow: 0px 0px 8px 0px rgba(0, 56, 117, 0.08);
    border: 1px solid #E6ECF4;
    border-radius: 4px;
    // max-height: 932px;
    min-height: 85vh;
    // max-height: 1000px;
    display: flex;
    justify-content: center;

    .menu-list-left {
        position: relative;
        width: 300px;
        border-right: 1px solid #E6ECF4;
        box-sizing: border-box;
        padding: 0 18px;
        padding-top: 40px;
        padding-bottom: 20px;
        display: flex;
        flex-direction: column;

        &::before {
            content: '';
            display: inline-block;
            position: absolute;
            top: 12px;
            left: 31px;
            bottom: 40px;
            z-index: 0;
            border-right: 1px dashed #E6ECF4;
            border-collapse: separate;
            border-spacing: 10px 50px;
        }

        .menu-flex {
            display: flex;
            align-items: flex-start;
        }

        .menu-title {
            font-family: PingFangSC-Semibold, PingFang SC;
            font-size: 20px;
            font-weight: 600;
            color: #324558;

            .menu-title-li {
                height: 28px;
                line-height: 28px;
            }

            .menu-list-child {
                display: flex;
                flex-direction: column;

                .menu-title-item {
                    font-size: 14px;
                    font-family: HiraginoSansGB-W3, HiraginoSansGB;
                    font-weight: normal;
                    color: #324558;
                    margin-top: 20px;

                    &:last-child {
                        margin-bottom: 20px;
                    }
                }
            }

        }
    }

    .content-view-right {
        position: relative;
        overflow: hidden;
        padding-top: 40px;
        padding-left: 23px;
        flex: 1;
        .content-view-outlet {
            overflow-x: hidden;
            padding-top: calc(84px + 28px);
            height: 940px;
        }
    }
}

.menu-active {
    color: #0FB187 !important;
}
