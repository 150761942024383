// 服务器最低配置
.claim-table {
    margin-top: 24px;
    border-radius: 4px;
    border: 1px solid #E6ECF4;
    width: 840px;

    .claim-table-tr {
        border-bottom: 1px solid #E6ECF4;
        display: flex;

        &:last-child {
            border-bottom: 0;
        }

        .claim-table-td {
            font-family: PingFangSC-Regular, PingFang SC;
            font-size: 14px;
            font-weight: 400;
            color: #324558;
            padding: 14px 23px;
            border-right: 1px solid #E6ECF4;
            flex: 1;

            p {
                line-height: 20px;
                margin-bottom: 8px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            &:first-child {
                display: flex;
                align-items: center;
                background: #F2F7FF;
                width: 154px;
            }

            &:last-child {
                border-right: 0;
                width: 341px;
            }
        }
    }
}

// IOS下载说明
.ios-explain {
    >span {
        display: inline-block;
        font-size: 14px;
        font-family: HiraginoSansGB-W3, HiraginoSansGB;
        font-weight: normal;
        color: #324558;
        line-height: 28px;
    }
    .ios-explain-list {
        >span {
            display: inline-block;
            font-size: 14px;
            font-family: HiraginoSansGB-W3, HiraginoSansGB;
            font-weight: normal;
            color: #324558;
            line-height: 28px;
        }

        .ios-list-ul {
            padding-left: 24px;

            >div {
                margin-top: 8px;
            }

        }
    }
}

// 架构说明
.architrave {
    .architrave-box {
        font-size: 14px;
        font-family: HiraginoSansGB-W3, HiraginoSansGB;
        font-weight: normal;
        color: #324558;
        line-height: 28px;
        .architrave-tab{
            padding-left: 28px;
            li{
                list-style: disc;
            }
        }
    }
}
