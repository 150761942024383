.navTitle {
    font-size: 28px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #324558;
    // line-height: 40px;
    width: 100%;
    padding-bottom: 39px;
    position: absolute;
    top: 40px;
    border-bottom: 1px solid #E6ECF4;
    background-color: #fff;

}
