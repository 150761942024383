@import './../assets/iconfont/iconfont.css';

body,
html,
ul,
ol,
p {
    padding: 0;
    margin: 0;
}

body,
html {
    color: #324558;
    font-size: 14px;
    width: 100%;
    height: 100%;
    background: #F6FCFF;
    font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "PingFang SC", "Microsoft YaHei", "Source Han Sans SC", "Noto Sans CJK SC", "WenQuanYi Micro Hei", sans-serif;
}

#root {
    width: 100%;
    height: 100%;
}

a {
    text-decoration: none;
    color: #324558;
}

li {
    list-style: none
}
// 拷贝
.sh-copy {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    img {
        cursor: pointer;
        padding-right: 8px;
    }

    span {
        font-size: 14px;
        font-family: HiraginoSansGB-W3, HiraginoSansGB;
        font-weight: normal;
        color: #324558;
        line-height: 28px;
        padding-right: 8px;
    }
}

// 包裹所有内容的app 宽度1200
// @con_width: 1200px;
// .app {
//     width: @con_width;
//     margin: 0 auto;
//     margin-top: 20px;
// }
