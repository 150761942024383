.menu-icon-box{
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 18px;
    z-index: 1;
    
}
.meun-icon {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed #C1CFDD;
    border-radius: 50%;
    &.is-active{
        border-color:#0FB187
    }
}

.meun-icon-item {
    width: 16px;
    height: 16px;
    background: #C1CFDD;
    border-radius: 50%;
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    color: #FFFFFF;
    font-weight: 500;
    text-align: center;
    line-height: 16px;
    &.is-active{
        background:#0FB187
    }
}
