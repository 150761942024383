.verify-item {
    .ant-input-affix-wrapper {
        border-right-width: 0;
    }

    .ant-input-group-addon {
        border-left-width: 0;
        border-color: #e6ecf4;
        background-color: #fff;
        border-radius: 0 4px 4px 0;

        .ant-btn {
            width: 116px;
            height: 28px;
            background: #e6ecf4;
            border:0;
            border-radius: 4px;
            padding: 0;

            span {
                font-size: 12px;
                height: 20px;
                color: #007aff;
            }
        }
    }
}
