.acitve-box {
    width: 440px;
    min-height: 390px;
    margin: 0 auto;
    margin-top: 60px;
    background: #FFFFFF;
    border-radius: 4px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .active-top-img {
        width: 80px;
        height: 80px;
        margin-top: 64px;

        &.isActive {
            background: url("./../../../assets/imgs/icon_successful_80.svg") no-repeat;
        }

        &.isSuccess {
            background: url("./../../../assets/imgs/icon_activation_80.svg") no-repeat;
        }
    }

    p {
        text-align: center;
    }

    .acitve-content {
        margin-top: 32px;
        font-size: 16px;
        font-family: HiraginoSansGB-W6, HiraginoSansGB;
        font-weight: normal;
        color: #324558;
        line-height: 24px;
    }

    .acitve-content-tip {
        margin-top: 16px;
        font-size: 14px;
        font-family: HiraginoSansGB-W3, HiraginoSansGB;
        font-weight: normal;
        color: #324558;
        line-height: 21px;
    }

    .ant-btn.success-btn {
        margin-top: 64px;
        width: 320px;
        background-color: #0FB187;
        border-color: #0FB187;
        height: 40px;
        border-radius: 4px;

        &:hover,
        &:active,
        &:focus {
            background-color: #0FB187;
            border-color: #0FB187;
        }

        &:disabled {
            background: #E6ECF4;
            border-color: #E6ECF4;
        }

        a {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 20px;
            &:hover{
                color: #FFFFFF;
            }
        }
    }

}
