.doc-container {
    width: 1200px;
    margin: 0 auto;
    padding: 40px 0;
    h1 {
        font-size: 18px;
        color: #000;
        font-weight: 600;
        text-align: center;
    }

    p {
        text-indent: 2 em;
        font-size: 14px;
        color: #000;
        text-align: left;
    }

    h2 {
        text-indent: 2 em;
        font-size: 14px;
        color: #000;
        text-align: left;
        font-weight: 600;
    }
}
