.login {
    width: 100%;
    height: calc(100vh - 60px);
    background: url("./../../../assets/imgs/img_bg_free_login_1920.png") no-repeat;
    background-size: 100% 100%;

    .login-box {
        width: 1200px;
        height: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;

        .login-box-left {
            width: 600px;
            margin-right: 120px;

            .left-title {
                position: relative;
                font-size: 48px;
                font-family: SourceHanSansCN-Heavy, SourceHanSansCN, -apple-system, '微软雅黑';
                font-weight: 800;
                color: #FFFFFF;
                line-height: 40px;

                img {
                    position: absolute;
                }

                img:nth-child(2) {
                    left: 0;
                    top: -63px;
                }

                img:nth-child(3) {
                    right: 0;
                    top: 15px
                }
            }

            .left-text-middle {
                margin: 40px 0;

                span {
                    display: block;
                    width: 100%;
                    font-size: 20px;
                    font-family: SourceHanSansCN-Normal, SourceHanSansCN, -apple-system, '微软雅黑';
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 28px;
                }
            }

            ul>li {
                margin-bottom: 24px;
                font-size: 20px;
                font-family: SourceHanSansCN-Bold, SourceHanSansCN, -apple-system, '微软雅黑';
                font-weight: bold;
                color: #FFFFFF;
                line-height: 28px;
                display: flex;
                align-items: center;

                span {
                    font-size: 20px;
                    font-family: SourceHanSansCN-Normal, SourceHanSansCN, -apple-system, '微软雅黑';
                    font-weight: 400;
                    padding-left: 8px;
                }
            }
        }

        .login-box-right {
            width: 480px;
            padding-top: 60px;
            padding-bottom: 24px;
            background: rgba(255, 255, 255, 0.96);
            border-radius: 10px;

            .login-right-form {
                padding: 0 40px;
            }

            a {
                text-decoration: none;
                color: #007aff;
            }

            .login-tip {
                font-family: HiraginoSansGB-W6, HiraginoSansGB, -apple-system, '微软雅黑';
                font-weight: normal;
                color: #324558;
                font-size: 17px;
                line-height: 24px;
                margin-bottom: 32px;
            }

            .ant-input {
                padding: 4px
            }

            .ant-btn.btn-form {
                margin-top: 12px;
                background-color: #0FB187;
                border-color: #0FB187;
                height: 36px;
                padding: 0 42px;
                border-radius: 4px;

                &:hover,
                &:active,
                &:focus {
                    background-color: #0FB187;
                    border-color: #0FB187;
                }
            }

            .ant-btn.btn-form[disabled] {
                background-color: #e6ecf4;
                border-color: #e6ecf4;
            }

            .login-footer {
                margin-top: 60px;
                padding: 0 40px;
                padding-top: 23px;
                border-top: 1px dashed #E6ECF4;
                display: flex;
                justify-content: space-between;

                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                line-height: 20px;

                .login-foo-acc {
                    span {
                        color: #324558;
                    }

                    b {
                        font-weight: 400;
                        color: #007AFF;
                        cursor: pointer;
                    }
                }
            }

            .login-foo-forget {
                cursor: pointer;
                color: #7F8A94;
            }
        }

    }
}
