.touch-down {
    display: inline-flex;
    align-items: center;
    line-height: 28px;
    cursor: pointer;
    img{
        width: 14px;
    }
    b {
        margin-left: 6px;
        font-size: 14px;
        font-family: HiraginoSansGB-W3, HiraginoSansGB;
        font-weight: normal;
        text-decoration:underline;
        color: #007AFF;
    }

}
