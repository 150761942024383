@font-face {
  font-family: "iconfont"; /* Project id 2942787 */
  src: url('./iconfont.eot?t=1637062398235'); /* IE9 */
  src: url('./iconfont.eot?t=1637062398235#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./iconfont.woff2?t=1637062398235') format('woff2'),
       url('./iconfont.woff?t=1637062398235') format('woff'),
       url('./iconfont.ttf?t=1637062398235') format('truetype'),
       url('./iconfont.svg?t=1637062398235#iconfont') format('svg');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-icon_kuberntes_cluster_highlight_16:before {
  content: "\e623";
}

.icon-icon_persistent_volume_highlight_16:before {
  content: "\e62e";
}

.icon-icon_persistent_volumed_eclaration_highlight_16:before {
  content: "\e633";
}

.icon-icon_server_topology_highlight_16:before {
  content: "\e636";
}

.icon-icon_user_management_highlight_16:before {
  content: "\e638";
}

.icon-icon_local_user_group_highlight_16:before {
  content: "\e627";
}


